
$transa: rgb(0,0,0,0.5);
$transb: rgb(0,0,0,0.7);
$primary: orangered;
$secondary:  var(--blue);
$lightSec: #76737c;
$lightPri: #fcbf49;
$gen: #c0786a;
$white: var(--white);
$black: var(--bla);


.header{

    .theme{
        margin-top: -8px;
    }
}

button{
    background: none;
    }
    .header{
        display: flex;
        border-bottom: 0.5px solid grey;
        gap: 6px;
        width: 100%;
        align-items: center;
        padding-top: 10px;
        align-items: center;

        .wrap{
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 90%;
            gap: 5px;

            .wrap-tool{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                top: -10px;
                right: 35px;

                button{
                    color: #123456;
                }

               
                // gap: 10px;
                .icon{
                    font-size: 20px;
                    color: $secondary;

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }

        .wrapser{
            border: 1px solid $primary;
            display: flex;
            align-items: center;
            // padding: 3px;
            border-radius: 5px;

            input{
                color: black;
            }
            button{
                color: #123456;
                background: none;

                .icon{
                    font-size: 18px;
                }
            }

        }
    }
    .logoDash{
        width: 100px;
        border-right: 0.5px solid grey;
        padding: 10px;
    }
    
    .nav-wrap{
      position: sticky;
      top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 90vh;
        margin: 0  10px  10px 0;
        background-color: $white;
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: space-between;
        box-shadow: 10px 6px 6px -5px dimgrey;
        border-radius: 20px;
        border-left: 2px solid $primary;
        border-bottom: 0.5px solid grey;
        border-top: 0.5px solid grey;

        .sub{
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 10px;
            transition: 0.5S ease;

           .wrapser{
            border: 2px solid $primary;
            display: flex;
            align-items: center;
            border-radius: 10px;
           }
        }
        .sub.on{
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 10px;
            transition: 1s ease;
            width: 35px;
            overflow: hidden;
        }

      .icons{
            display: flex;
            flex-direction: column;
            align-content: center;
            margin: 5px;

            button{
                background: none;

               

                .icon{
                    font-size: 25px;
                    background-color: none;
                    color: $secondary;

                    &:hover{
                        color: $primary;
                    }
                }
            }
        }
        .poss{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 23px;
            margin-top: 40px;


            
            button{
                background: none;

                .icon{
                    font-size: 25px;
                    background-color: none;
                    color: $secondary;
                }
            }
        }
        .closeBtn{
            position: absolute;
            bottom: 50px;
            right: -10px;
            border-radius: 50px;
            height: 30px;
            width: 30px;
            background-color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid $primary;
            box-shadow: -3px 3px 3px dimgrey;
            

            .icon{
                font-size: 55px;
                color: $primary;
                transition: 0.5s ease;
            }
            .icon.on{
                transform: rotate(180deg);
            }
        }
    }
    .flexHead{
        display: flex;

        .dashboard{
            border: 1px solid grey;
            width: 100%;
            margin: 5px;

            .firstRow{
                display: flex;
                justify-content: start;
                gap: 10px;
                margin: 5px;

                a{
                    padding: 20px;
                    border-radius: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-shadow: 3px 3px 5px grey;
                    position: relative;

                    small{
                        color: $white;
                        position: absolute;
                        top: -5px;
                        padding: 5px;
                        border-radius: 50px;
                        width: 10px;
                        height: 10px;
                        border: 3px solid $white;
                        right: -5px;
                        background-color: $primary;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
.ListPage{
    border: 1px solid grey;
    width: 97%;
    margin: 5px;
    border-radius: 10px;
    padding-left: 5px;

    .failingPage{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        height: 100%;

        .wideIcon{
            color: grey;
            font-size: 250px;
        }
        button{
            color: red;
            font-weight: 600;
            padding: 5px;
            border: 1px solid red;
            background-color: rgb(212, 18, 18, 0.4);
            border-radius: 7px;
        }
    }


    .contt{
  display: flex;
  align-items: center;
  border: 1px solid grey;
  height: 70px;
  border-radius: 10px;
  padding-left: 3px;
  margin-right: 5px;
  margin-bottom: 3px;
  gap: 5px;
  max-width: 91%;
  justify-content: space-between;
  overflow: hidden;

  .grea{
    display: flex;
    flex-direction: column;
    margin: auto;
    padding:5px 0 5px 0;
    width: 100%;
    overflow: hidden;
    
  }
 
  &:hover{
    .icon{
        display: inline;
    }

  }
        img{
            width: 100px;
            height: 60px;
            border-radius: 10px;
        }
        p{
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
            
        }
        h3{
            margin: 0;
            padding: 0;
            small{
                color: grey;
                font-weight: 100;
                font-size: 10px;
            }
        }
        p{
            margin: 0;
            width: fit-content;
            padding: 0;
        }
        button{
            width: 80px;
            display: flex;
            .icon{
                font-size: 25px;
                margin-right: 5px;
                display: none;
            }
            .icon.delete{
                color:red;
            }
        }
    }
    h2{
        text-transform: capitalize;
    }

    .chartWrap{
        // width: 100%;
        // overflow: hidden;
        
        .chart{
            // width: 97%;
        }
    }

}

.imgPro{
    border-radius: 50%;
    height: 30px;
    width: 30px;
    margin-bottom: -10px;
}
.sideWrapper {
    width: 95%;
    height: 90vh;
    overflow: auto;
    padding: 10px;
    
  }
  
  .sideWrapper::-webkit-scrollbar {
    width: 6px; 
  }
  
  .sideWrapper::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  .sideWrapper::-webkit-scrollbar-track {
    background: transparent; 
  }
  
  .sideWrapper {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;


    &:nth-child(n){
        width: 95%;
    }
  }
  