.notiWrap{
    position: fixed;
    top: -100px;
    left: 50%;
    right: 50%;
    width: fit-content;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border-radius: 10px;
    padding: 0px;
    transition: 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    p{
        width: fit-content;
        min-width: 200px;
        text-align: center;
    }
}

.notiWrap.out{
    top: 30px;

}