
/* Heading Styles */

$transa: rgb(0,0,0,0.5);
$transb: rgb(0,0,0,0.7);
$primary: orange;
$secondary: #38465c;
$lightSec: #76737c;
$lightPri: #fcbf49;
$gen: #c0786a;
$white: var(--white);

textarea{
    background-color: $white;
    color: var(--bla)
}

.aboutPage{
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    .coverAbt{
        display: flex;
        justify-content: center;
    }
   .About{
        text-align: center;
        width: 100%;
        max-width: 1024px;
        h1 {
            text-decoration: underline;
        }
        .container {
            .about {
                width: 98%; 
                text-align: left;
                margin: 10px;
                gap: 10px;

                img{
                    border-radius: 10px;
                    transition: 0.5s ease;
                    margin-top: 10px;
                    &:hover{
                        scale: 1.05;
                    }
                }
                h2{
                    text-decoration: underline;
                    margin: 0;
                }
            }

        }
   }    



}


//Phone responsiveness

@media screen and (max-width: 767px ){
        .aboutPage{
            .container{
                .about{
                    flex-direction: column;
                    display: flex;
                    justify-content: center;

                    img{
                        width: 70%;
                    }

                }
            }
        }
}

@media screen and (min-width: 767px) {
    .aboutPage{
        .container{
            .about{
                display: inline-block;
                margin-bottom: 30px;

               
                img{
                    width: 300px;
                    height: 300px;
                    float: left;
                    margin: 15px;

                }

                
                &:nth-child(3n - 1){
                    img{

                        float: right;
                    }
                }

            }
        }
    }

}


.contAll{
    background-image: url('../assets/imgbg1.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    color: #fff;
   position: relative;
}
.coverCont{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 0;
    background-color: rgb(0, 0, 0, 0.6)
}

.contact-container{
    position: relative;
    .icon{
        border-radius: 10px;
        padding: 5px;
        margin-right: 5px;
    }
 
}
.msgus{
    width: 90%;
    max-width: 500px;
    input, textarea{
        border: 1px solid #555;
        border-radius: 5px;
        margin:5px;
        padding: 10px;
        width: 100%;
        resize: none;
    }
      button{
        background: $primary;
        padding: 10px;
        margin: 0 0 10px 0;
        color: #ffffff;
        border-radius: 5px;
    }
}

// Service Page

.servicePage{
    background-image: url('../assets/cons.jpg');
    background-size: cover;
    
    .serviceInner{
        display: flex;
        align-items: center;
        justify-content: center;
        
        
    }
    .serviceCover{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgb(0, 0, 0, 0.7);
    }
    .serv-container{
        color: #fff;
        z-index: 2;
        max-width: 1024px;
        .content-wrap{
            display: flex;
            flex-direction: column;
            h1{
                border-bottom: 2px solid ;
                padding: 10px 7px 0 0;
                width:fit-content;
            }
            .content{
                margin: 10px;
                font-size: 1.2rem;
                
                img{
                    float: left;
                    width: 40%;
                    border-radius: 10px;
                    box-shadow: 10px 10px 10px grey;
                    margin:0 15px;
                }
            }

            &:nth-child(2n + 2){
                img{
                    float: right;
                }
            }
        }
    }
}

//feed page
    .feed-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 10px 10px 10px 10px;

        .feed-container{
            max-width: 1024px;
            width: 100%;
            .pinned-container{
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                margin-bottom: 20px;
                position: relative;
                border-radius: 15px;

                img{
                    width: 100%;
                    max-width: 700px;
                border-radius: 15px;

                }
                div{
                    max-width: 700px;
                    padding: 10px 0  5px 0;
                border-radius: 0 0 15px 15px;
                    width: 100%;
                    position: absolute;
                    bottom: 0;
                    color: #fff;
                    font-size: large;
                    font-family: cursive;
                    background-color: $transb;
                    text-align: center;
                }
            }

@media screen and (max-width:767px) {
    .single-wrapA{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        // background: #000;

        .content-wrap{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            p{
                text-align: center;
                padding: 0;
                width: 100%;
                margin: 0;
            }
        }
    }
}

            .poster-container, .single-wrapA{
                margin-bottom: 30px;
                max-width: 800px;
                .title{
                    margin: 0;
                    h3{
                        text-decoration: underline;
                        margin: 0;
                        font-size: 25px;
                        text-transform: capitalize;

                    }
                    sup{
                        font-size: 10px;
                        text-align: left;
                    }
                    
                }
                .wrap{
                    display: flex;
                    border-right: 0.2px solid grey;
                    border-bottom: 0.2px solid grey;
                    gap: 10px;
                    border-radius:10px;
                    position: relative;
                    padding-right: 10px;
                    overflow: hidden;
                    
            img{
                max-height: 150px;
                    max-width: 30%;
                    border-radius: 5px 0 0 5px;
            }
            .text{
                display: -webkit-box;
                -webkit-line-clamp: 6;
                -webkit-box-orient: vertical;
                text-overflow: ellipsis;
                overflow: hidden;
                margin-bottom: 20px;
                p{
                    margin: 0;
                }
            }
                button{
                height: 30px;
                position: absolute;
                bottom: 2px;
                border-left: 2px solid $primary;
                border-top: 2px solid $primary;
                right: 2px;
                background-color: #fff;
                border-radius: 10px;
                box-shadow: 5px 5px 5px grey;
                a{
                    color: #000;
                }
            }
        }
    }
    .content-wrap{
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 97%;
        img{
            max-width: 400px;
            width: 100%;
            border-radius: 10px;
            border: 1px solid grey;
        }
        p{
            width: 90%;
            
        }
    }
}
}


// Search Page
.searchPage{

    .search-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .search-item{
            max-width: 1024px;
        }
    }
    
        .wrap-rel{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 20px;
            a{
                color: #000;
                margin: 0;
            }
            .relDiv{
                background-color: #c0c0c0;
                border-radius: 5px;
                width: 250px;
               
                img{
                    min-width: 0;
                    max-width: 100%;
                    border-radius: 5px 5px 0 0;
                }
                .text-rel{
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    margin: 3px 10px 10px 10px;
                    border-top: 2px solid $primary;
                    padding-top: 10px;
                    h2{
                        margin: 0;
                        font-size: 18px;
                    }
                    p{
                        margin: 0;
                        font-size: 14px;
                        padding-bottom: 2px;
                    }
                }
            }
        }
    
}


