.load-wrapper{
    position: fixed;
   top: 0;
   left: 0;
  right: 0;
  bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(5px);
    z-index: 10;

    .good, .bad{
      padding: 10px;
      border-radius: 50%;
      margin: 10px;
     display: flex;
     align-items: center;
     justify-content: center;

    }
    .good{
      border: 3px solid green;
      &:nth-child(n){
        color: inherit;
        font-size: 35px;
      }
    }
    .bad{
      border: 3px solid red;
      
      &:nth-child(n){
        color: inherit;
        font-size: 35px;
      }
    }
    


}
.load-wrapper.hidden{
    display: none;
}
.load-wrapper .loading{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: hsla(0, 0%, 0%, 0.7);
    backdrop-filter: blur(3px);
    position: absolute;
    border-radius: 10px;
    padding: 5px;
    b{
      color: #fff;
    }
}



.load-wrapper .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .load-wrapper .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  .load-wrapper .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .load-wrapper .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .load-wrapper .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
   
  }

  // Mini Loading
  

  .miniload-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    z-index: 2;

}

.miniload-wrapper.hidden{
    display: none;
}
.miniload-wrapper .loading{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: hsla(0, 0%, 0%, 0.7);
    backdrop-filter: blur(3px);
    position: absolute;
    border-radius: 10px;
    padding: 5px;
    b{
      color: #555;
    }
}



.miniload-wrapper .lds-ring {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .miniload-wrapper .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 90%;
    height: 90%;
    margin: 8px;
    border: 3px solid #555;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #555 transparent transparent transparent;
  }
  .miniload-wrapper .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .miniload-wrapper .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .miniload-wrapper .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
   
  }
  