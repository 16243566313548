


//Declaring * variables



$transa: rgb(0,0,0,0.5);
$transb: rgb(0,0,0,0.7);
$primary: orangered;
$secondary: #003049;
$lightSec: #76737c;
$lightPri: #fcbf49;
$gen: #c0786a;
$white: var(--white);
$black: var(--bla);
$blue: var(--blue);

.wrapHome{
    width: 100%;
    overflow: hidden;
}
input{
    background-color: transparent;
    color: $black;

}
a{
    color: $blue;
}
a:hover{
    color: $primary;
}
.icon{
    cursor: pointer;
}
.searcherWrap{
    width: 100%;

    .search-bar{
        background: red;
        width: 100%;
        display: none;
    }
}

//Header component starts



.overall-wrap{
    width: 100%;
    background-color: $white;
    color: $black;
    z-index: 10;
    
    .overall{
        background: inherit;
        width: 100%;
        

        }

        
        .address{
          
        
            .sideOut{
                gap: 5px;
                max-width: 600px;
                align-items: center;
                margin-top: 10px;
                font-family: 'Catamaran', sans-serif;
                div{
                    a{
                        // color: inherit;
                        font-weight: 600;
                        font-size: 15px;
                        margin-right: 5px;
                    }
                }
                
                .search-bar{
                    background-color: #fff;
                    border: 1px solid $primary;
                    padding: 5px;
                    margin-right: 5px;
                    border-radius: 10px;
                    display: flex;
                    align-items: center;
                    input{
                        background-color: #000;
                        display: none;
                    }
                    
                    .icon{
                        color: $primary;
                    }
                
            }
            
            
        }
        .sideOut.active{
           
            input{
                display: block;
            }
        }
    }

        // Header Responsiveness
        // Mobile
    @media screen and (max-width: 767px) {

        .overall{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background: white;
            .inner-content{
                background-color: rgb(0, 0, 0, 0.97);
                // height: 120px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                margin-bottom: 10px;
                .mobile{
                    cursor: pointer;
                    // margin-top: -50px;
                    padding-bottom: 50px;
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    // flex-direction: column;
                    justify-content: center;
                    gap: 5px;
                    width: 100%;

                    .span{
                        width: 90%;
                    }

                    .wrap{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        max-width: 300px;
                        z-index: 4;

                        nav{
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .navbar{
                                width: 100%;
                                display: none;
                                align-items: center;
                                justify-content: center;

                                .nav-container{
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                }
                            }
                            .navbar.active{
                                display: flex;
                            }

                            .wrapOuter{
                                width: 100%;

                                a{
                                    
                                    border-bottom: 1px solid orangered;
                                }


                                .span, .spanIn{
                                    display: flex;
                                    width: 100%;
                                    position: relative;

                                    .btnZ, .btnQ{
                                        position: absolute;
                                        right: 10px;
                                        top: 50%;
                                        bottom: 50%;
                                        transform: translateY(-50%);
                                        transition: 0.5s ease;
                                    }
                                    .btnZ{
                                        display: none;
                                    }

                                }

                                .navSpread{
                                    display: none;
                                    a{
                                        padding-left: 30px;
                                    }
                                }
                                

                            }
                            .wrapOuter.true{
                                .navSpread{
                                    display: block;
                                }
                                .btnZ{
                                    transform: translateY(-50%) rotate(-180deg);

                                }
                                    .navSpread.in{
                                        display: none;
                                        
                                       
                                    }
                                .spreader.in.active{
                                    
                                    .btnQ{
                                        transform: translateY(-50%) rotate(-180deg);
                                        
                                    }
                                    .navSpread.in{
                                        display: block;
                                        
                                        
                                    }
                                        
                                        .spreader.in{
                                            a{
                                                padding-left: 50px;
                                            }
                                        }
                                    }
                                }
                        
                        }
                    }
                    
                    
                    .search-bar{
                        background-color: #fff;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #000;
                        padding: 7px;
                        margin-top: 10px;
                        width: 100%;
                        border-radius: 30px;


                        input{
                            width: 92%;
                    }
                    }
               }

            }
            .nav-container{
                display: none;
            }
            .address{
                display: flex;
                justify-content: center;
                position: relative;
                .sideOut{
                    display: none;
                }
            }


            .wrapTwoNav{
                display: flex;
                flex-direction: column;
                background: #fff;
                margin-top: -40px;
                width: 80%;
                border-radius: 20%;
                padding: 20px;
                align-items: center;
                justify-content: center;
                gap: 20px;
                z-index: 3;

                img{
                    width: 170px;
                }

                .contactBtn{
                    background: orangered;
                    border-radius: 30px;
                    padding: 8px;

                    a{
                        color: #fff;
                    }
                }
            }
            .mobile.naver{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                
                nav, .navbar, .nav-container, .nav, .navSet{
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;


                }
            }

        }

    }
    //Pc
    @media screen and (min-width: 767px) {
        .overall{
            position: fixed;
            justify-content: space-between;
            width: 100%;
            display: flex;
            .nav-container{
                display: flex;
            }
            .sideOut{
                display: flex;
            }
            .mobile{
                display: none;
            }  
        }
        /* Header Responsive Ends here */
       
        
}

/* Menu Starts here */
// mobile
@media screen and (max-width:767px) {
   
    .pcHeader{
        display: none;
    }

    nav{
        
    
    
    .navbar{
        transition: 0.5s ease;

        .search-bar{
            display: none;
        }
        
    }
    .navbar.active{
        transition: 0.5s ease;
        display: flex;
        
        .search-bar{
            display: block;
            border: 1px solid $primary;
            width: fit-content;
            float:right;
            margin: 0;
            border-radius: 7px;
            padding: 7px;
            display: flex;
            align-items: center;
            
            input{
                background-color: inherit;
            }
            .icon{
                color: $primary
            }
    }
    .nav-container{
        display: flex;
        .nav{
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 150px;
            align-items: start;
            text-align: center;
            background-color: #2f2f2f;
            overflow: hidden;
            width: 90%;
            
            
            
            a{
                color: #fff;
                text-align: left;
                text-transform: capitalize;
                display: flex;
                height: 15px;
                font-size: 13px;
                border-bottom: 1px solid orangered;
                padding: 10px 0;
                padding-left: 10px;
                align-items: center;
                width: 97%;
                
                &:hover{
                    color: #fff; 
                    background-color: orangered;
                }
                
                
             
            }  

            &:hover {
                a{
                    &:hover{
                        color: #fff; 
                        background-color: orangered;
                    }
                }
            }
            .navHover{
                width: 100%;
            }
        
            .linkParent, .attain{

                .navSpread{
                    width: 100%;

                    a{
                        padding-left: 30px;
                    }
                }
                .inner{
                    position: relative;

                    .btnA{
                        position: absolute;
                        top: 10px;
                        right: 27px;
                        .iconb{
                            transition: 0.5s ease;
                            transform: rotate(0deg);
                            color: #fff;
                        }
                        .iconb.transb{
                            transform: rotate(180deg);
                            font-size: 15px;
                            
                        }
                    }
                    
                }

                .grandChild{
                    display: none;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;

                    div{
                        width: 100%;

                        a{
                            padding-left: 50px;
                        }
                    }
                }
            }
            .grandChild.active{
                display: flex;
            }
              .icon{
                    transition: 0.5s ease;
                    transform: rotate(0deg);
                    color: #fff;
                }
                .icon.trans{
                    transition: 0.5s ease;
                    transform: rotate(180deg);
                    font-size: 15px;

                }
                .child{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    
                   
                        
              } 

            
              .navSet{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: start;
                justify-content: start;

               

            }
        }
    }
}

}



.attain{
    display: block;
    width: 100%;
    position: relative;
    
    a{
        font-size: 13px;
        font-weight: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;

    }
        .btnA{
            margin-right: 15px;
            position: absolute;
            bottom: 5px;
            right: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            color: #fff;

            &:hover{
                border: 1px solid white;
            }
        }
    .btnI{
        color: #fff;
    }
    
}




}



// pc
@media screen and (min-width:767px) {
    /* Nav start */
    // Block Menu Nav & no icon needed

    .btnI{
        color: #555;
    }
    .mobile{
       display: none;
     }
     .overall{
        flex-direction: column;
        position: relative;
        align-items: center;
        justify-content: center;
        height: 110px;
        background: #000;

     }
     .inner-content{
         z-index: 3;
         height: 60px;
         width: 100%;
         display: flex;
         align-items: center;
         justify-content: center;
     }

     .inner-content, .wrapTwoNav{
        
         
         .pcHeader{
             width: 80%;
             display: flex;
             align-items: center;
             justify-content: space-between;
             z-index: 2;

             .search-bar{
                display: block;
                background: #fff;
                width: fit-content;
                float:right;
                margin: 0;
                border-radius: 30px;
                padding: 7px;
                display: flex;
                align-items: center;
             }
             .nav{
                display: flex;
                gap: 10px;

                a{
                    color: #a39f9f;
                    font-size: 13px;
                    height: 20px;
                    display: flex;
                    align-items: center;

                }

                .navSet{
                    display: flex;
                    gap: 10px;

                    .attain{
                        display: flex;
                        align-items: center;
                        height: 20px;
                        justify-content: center;
                        gap: 1px;


                        .btnA{
                            font-size: 10px;
                            color: #fff;
                            margin-top: 4px;
                        }

                    }
                        .navSpread{
                            position: absolute;
                            background: #2f2f2f;
                            
                            .inner{
                                // position: relative;
                                padding: 7px;
                                width: 250px;
                                height: 30px;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;
                                border-bottom: 1px solid orangered;

                                .span{
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                }
                                .btnK{
                                    font-size: 10px;
                                    color: #fff;
                                    transform: rotate(-90deg);
                                }
                                .grandChild{
                                    display: none;
                                }

                                a{
                                    color: #fff;
                                }
                                &:hover{
                                    position: relative;
                                    background: orangered;

                                    .grandChild{
                                        display: block;
                                        position: absolute;
                                        left: 100%;
                                        top: 0.5px;
                                        background: #2f2f2f;
                                        
                                        
                                        .grandClass{
                                            padding: 6.8px;
                                            width: 250px;
                                            height: 30px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: start;
                                            border-bottom: 1px solid orangered;

                                           &:hover{
                                            background: orangered;
                                           
                                           }
                                        }
                                    }
                                }
                                
                            }
                            // operate here


                        
                        }
                }

             }
            }
            
        }
        .wrapTwoNav{
            padding: 10px;
            background: #fff;
            height: 70px;
            width: 90%;
            max-width: 1200px;
            position: absolute;
            bottom: -70px;
            z-index: 2;
            border-radius: 50px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            // gap: 10px;
            
            img{
                width: 200px;
            }
            .pcHeader{
                width: 60%;
                 z-index: 30;
                 

                
                span{
                    width: 100%;

                    .nav-container{
                        width: 100%;
                        
                        .nav{
                            width: 100%;
                            display: flex;
                            gap: 35px;
                            align-items: center;
                            justify-content: center;

                            a{
                                color: #555;
                                white-space: nowrap;
                            }

                            .attain{
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                
                            }
                            .navSet{
                                display: flex;
                                gap: 35px;
                                align-items: center;
                                justify-content: space-between;
                            }
                        }
                    }
                }
            }
            .contactBtn{
                background: orangered;
                border-radius: 30px;
                padding: 8px;

                a{
                    color: #fff;
                }
            }

        }

       
        .nav-container{
            width: 100%;
            
            .nav{
                width: 100%;
                display: flex;
                gap: 35px;
                align-items: center;
                justify-content: center;

                .wrapOuter{
                    
                    .span{

                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 3px;
                        position: relative;
                    }
                    
                    .btnZ{
                        display: none;
                        font-size: 12px;
                    }
                    
                    .navSpread{
                        background: #2f2f2f;
                        position: absolute;
                        top: 20px;
                        left: -5px;
                        display: none;
                        
                        .spreader{
                            width: 250px;
                            height: 40px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            border-bottom: 1px solid orangered;
                        }
                 
                       
                    }
                        






                }
                .wrapOuter.true{
                    position: relative;
                    
                    .navSpread{
                        // background: red;
                    }

                    .spanWrap{
                        height: 40px;
                        width: 100%;

                        .navSpread.in{
                            display: none;
                            position: absolute;
                            top: 0;
                            left: 100%;
                        }

                        &:hover{
                            
                            .navSpread.in{
                                display: block;
                            }
                        }
                    }


                    &:hover{

                            .navSpread{
                                display: block;
                                position: absolute;
                                top: 20px;
                                left: -5px;
                                
                                .spanIn{
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    gap: 3px;
                                    width: 95%;
                                    padding: 3px;

                                
                                    .btnQ{
                                        transform: rotate(-90deg);
                                    }


                                }
                                .spreader{
                                    padding-left: 7px;

                                    &:hover{
                                        background: orangered;
                                        
                                        a{
                                            color: #fff;
                                        }
                                    }
                                }
                              

                               
                            }
                    }
                }
            }
                
        }
              
          
}
}

.activehref{
    color: $primary;
}


/* Swiper Goes here */
.swiperContainer{
    width: 100%;
    background: #fff;
    position: relative;


// mobile
    @media screen and (max-width: 767px) {
        margin-top: -20px;
        
    }


    .swiperHead{
        .mySwiper{
            position: relative;
            .swiperSlide{
                width: 100%;
                margin-top: -8vh;
            }
            .coverImg{
                position: absolute;
                top: 0;
                padding: 0;
                bottom: 0;
                width: 100%;
                margin-bottom: 3px;
                right: 0;
                background: rgba(153, 245, 153, 0.2);
                
            }
                .info{
                    position: absolute;
                top:20%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: #fff;
                margin: 10px;
                text-transform: uppercase;
                min-width: 250px;
                h2{
                max-width: 500px;
                overflow: hidden;
                text-wrap: balance;
                font-family: 'Arvo', serif;

                small{
                    font-style: italic;
                    font-size: 23px;
                    text-align: left;
                }
                b{
                    color: $primary;
                }
                }
            }
            .innerText{
                position: absolute;
                top: 45%;
                color: #fff;
                left: 50%;
                transform: translateX(-50%);
                min-width: 200px;
                text-align: center;
            }
            .centLink{
                display: none;
                position: absolute;
                justify-content: center;
                width: 100%;
                z-index:2;
                font-weight: 600;
                font-size: 18px;
                // height: 100px;
                a{
                    background-color: $primary;
                    color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: 'Arvo', serif;
                    border: 1px solid #fff;
                }
            }   
        }

    }
}
        
        // Mobile
        @media screen and (max-width:767px) {
            .swiperContainer{
                
                .swiperHead{
                    .mySwiper{
                        .swiperSlide{
                height: 300px;
                width: 100%;
                            margin-top: -10vh;
                        }
                    }
            .info{
                min-width: 180px;
                max-width: 400px;
                
                h1{
                    font-size: 15px;
                    text-align: left;
                    padding: 10px;
                }
            }
            .centLink{
                flex-direction: column;
                gap: 8px;
                bottom: 0px;
                height: 180px;
                a{
                    padding: 3px;
                    width: 70%;
                    border-radius: 0 50px 50px 0;
                }
            }
            
            
        }
        
    }
}


        
        // pc 
        @media screen and (min-width:767px) {
            .swiperContainer{
                .swiperSlide{
                    height: 90vh;
                }
               
                .swiperHead{
                    .info{
                        overflow: hidden;
                        min-width: 200px;
                        width: fit-content;
                        
                        h1{
                            text-align: right;
                            width: fit-content;

                        }
                    }
                    .centLink{
                        gap: 25px;
                        top: 250px;
                        height: 180px;
                        
                        a{
                    width: 25%;
                }
            }
        }
    }        
}






//End of the swiper.
//Dealling with the hometext.

.hometext{
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px;
    font-family: 'Arvo', serif;
    gap: 10px;
    box-shadow: 2px 2px 3px grey;
    margin: 5px;
    border-radius: 3px;

    h1{
        border-right: 5px solid #123467;
        width: fit-content;
        padding-right: 15px;
    }
    p{
        font-family: 'Catamaran', sans-serif;
    }

    a{
        border: 2px solid $secondary;
        padding: 5px 10px ;
        border-radius: 3px;
        font-family: 'Catamaran', sans-serif;
        color: #000;
        font-weight: bold;
        color: $secondary;

        &:hover{
            border: 2px solid $primary;
            color: $primary;

        }
    }
}
@media screen and (max-width:767px) {
    .hometext{
        display: block;
    }
}


//post-container
.head-container{
    .homeware{
        margin: 20px;
        width: 100%;
        h1{
            text-align: left;
            color: $secondary;
            padding-bottom: 2px;
            border-bottom: 2px solid $primary;
            width: fit-content;
            font-family: 'Arvo', serif;
            
        }
    .allpost{
    width: 95%;
    
}

}
}

.wrap-cont{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

}
.post-container{
    display: flex;
    flex-direction: column;
    max-width: 350px;
    min-width: 150px;
    width: 98%;
    margin: 10px;
    // box-shadow:  5px 5px 4px $lightSec;
    border-radius: 5px;
    font-family: 'Catamaran', sans-serif;;
    position: relative;
    img {
        width: 100%;
        border-radius: 5px;
        height: 190px;
    } 
    .inner{
        position: absolute;
        bottom: 8px;
        color: white;
        background-color: $transb;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        right: 0;
        left: 0;
        padding: 5px;
        padding-bottom: 0;
        border-radius: 0 0 5px 5px;
        p{
            font-family: 'Catamaran', sans-serif;
        }
    }
    h2{
        margin: 0;
        text-decoration: underline;
        color: white;
        text-transform: capitalize;
    }
    p{
        padding: 0;
       margin: 0;

    }
    
}

// Feed hom styles

.feedHome, .subLink-wrap{
    // width: 95%;
    
    h1{
        margin-left: 5px;
        
    }
    .feedMap{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 10px;
        min-width: 200px;
        border-radius: 5px;
        width: 90%;
        .feedWrapper{
            max-width: 350px;
            margin: 10px;
            border-right: 3px solid $secondary;
            border-bottom: 1px solid $secondary;
            border-radius: 5px;
            padding: 10px;
            .feedWrap{

                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                text-overflow: ellipsis;
                overflow: hidden;
                font-family: "Catamaran", sans-serif;

            }
            h3{
                text-decoration: underline;
                font-weight: bold;
            }

            a{
                color: inherit;

                button{
                    background-color: inherit;
                    padding: 5px;
                    border: 1px solid $secondary;
                    margin-top: 10px;
                    cursor: pointer;

                    &:hover{
                    border: 1px solid $primary;


                    }
                }
            }
                a:hover{
                    color: $primary;
                }
        }
    }
}


.feedPage{
    width: 98%;
}

.marquee-container {
    overflow: hidden;
    width: 100%;
    max-width: 1024px;
  }
  
  .marquee-text {
    animation: marquee 30s linear infinite;
    
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}




.pathome{
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
            width: 170px;
            border-radius: 10px;
            padding: 10px;
            margin:0 10px;
        }
    }
        // }
    //Footer Component
    footer{
        font-family: "Catamaran", sans-serif;
        a{
            color: #fff;
        }
        background-color: $secondary;
        color: #fff;
        position: relative;
        .footer{
            justify-content: space-between;
            width: 100%;
            div{
            width: 32%;
        }
        
        .company{
            display: flex;
            justify-content: center;
            height: 100px;
            align-items: center;
            color: #fff;
            gap: 5px;
            img{
               width: 120px;
               margin-left: 25px;
               background: #efefef;
                border-radius: 10px;
                box-shadow: 2px 2px 3px grey;
            }
        }
        .contAbt{
            display: flex;
            align-items: center;
            justify-content:space-around;
            width: 95%;
            margin-left: 8px;
            .div{
                width: 100%;
                flex: 1;
            }
        }
        
        .contact-footer{
            width: 100%;
            span{
                display: flex;
                align-items: center;
                gap: 5px;

                .icon{
                    scale: 1.7;
                    
                }
            }
        }
        .abtus{
            flex: 1;
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 15px;
            width: 95%;


            a{
                width: 100%;
                word-wrap: break-word;
            }
            a:hover{
                color: #fff;
            }
            div{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                gap: 10px;
                .icon{
                    margin: 5px;
                    scale: 1.7;
                }
            }
        }
    }
        .allright{
            text-align: right;
            background-color: #f6cf9a;
        }
        i{
            margin-right: 30px;
            font-size: 12px;
            color: black;
        }
    }

    @media screen and (min-width:767px) {
        .footer{
        display: flex;

        }
    }
    @media screen and (max-width:767px) {
        
        .footer{
            display: block;
            flex-wrap: wrap;
            .contact-footer{
                flex: 3;
            }
            
        }
    }
    
    

    //Contact page.

.wrap-container{
    display: flex;
    justify-content: center;

    .wrapLogo{
        background-color: $lightPri;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        width: 50%;
        border-radius: 15px;
        margin-bottom: 20px;
        p{
            margin: 0;
            font-weight: 700;
            color: $white;
            font-size: 27px;
            font-family: 'Arvo', serif;

        }
        img{
            width: 30%;
            border-left: 3px solid $secondary;
            padding-left: 10px;
        }
       
    }
}
.contact-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    .main-contact{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 1024px;
        span{
            display: flex;
            align-items: center;
            font-size: 19px;
            word-wrap: break-word;
            
            .icon{
                color: $lightPri;
                font-size: 100px;
            }
        }
    }
}


a.activehref{
    // opacity: 0;
    color: orangered;
}
.loaderdiv{
    .miniload-wrapper{
        height: 38vh;

        .lds-ring {
            width: 150px;
            height: 150px;

            div{
                width: 100%;
                height: 100%;
                border: 10px solid grey;
                border-color: #555 transparent transparent transparent;

            }
          }
          @media screen and (min-width:767px) {
            height: 65vh;

          }
    }
}

.footwrap{
    width: 100%;
}



.headerImg{
    margin-top: -30px;
    position: relative;
    
    img{
        width: 100%;
    }
    .coverImg{
        
        position: absolute;
        top: 0;
        padding: 0;
        bottom: 0;
        width: 100%;
        margin-bottom: 3px;
        right: 0;
        background: rgba(153, 245, 153, 0.2);
        
        
    }
    .coverAll{
        position: absolute;
        margin-left: 10px;
        bottom: 35%;
        z-index: 4;
        h1{
            text-transform: capitalize;
            color: #fff;
            font-size: 40px;
            font-weight: 700;
            background: rgb(0, 0, 0, 0.05);
            backdrop-filter: blur(3px);
            border-radius: 30px;
        }
        .line{
            position: absolute;
            border-top: 4px solid orangered;
            width: 140px;
            margin-top: -20px;
       
            
        }
        small{
            color: #fff;
            margin-top: -10px;
            position: absolute;
            font-size: 15px ;
        }
    }
}








