
$transa: rgb(0,0,0,0.5);
$transb: rgb(0,0,0,0.7);
$primary: orange;
$secondary: #38465c;
$lightSec: #76737c;
$lightPri: #fcbf49;
$gen: #c0786a;
$white: var(--white);



//gallery
.galPage{
    .gal-wrapper{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
        .gal-container{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 10px;
            max-width: 1024px;
            .galimg-wrap{
                border: 1px solid #c0c0c0;
                border-radius: 5px;

                img{
                    width: 150px;
                    height: 150px;
                    object-fit: contain;
                    border-radius: 5px;
                }
            }
        }
    }
}

//Gallery Component
.gal-wrapper{
    margin-top: 20px;
    margin-bottom: 20px;
}
.sliderWrap{
    position: fixed;
    bottom: 0;
    top: 0;
    right: 0;
    left: 0;
    background-color: rgb(0, 0, 0 ,0.9);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    flex-direction: column;

    .closeGalBtn{
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        margin: 10px;
        font-size: 35px;
        background-color: $transb;
        border-radius: 50%;
        padding: 7px;
        z-index: 2;
        
    }
    .fullScreenImage{
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: -10;
        border-radius: 10px;
        width: 100%;

        img{
            width: 98%;
            object-fit: contain;
            max-height: 93vh;
            border-radius: 10px;
        }
    }
    .btns{
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .icon{
            color: #000;
            font-size: 28px;
            background: #fff;
            margin: 10px;
            border-radius: 50%;
        }
        

    }
    .range{
        padding: 10px;
        background-color: $transa;
        border-radius: 15px;
        color: #fff;
        position: absolute;
        bottom: 30px;
    }
}

// Handling Sublinks
.subLink-wrap{
    .subMain{
        .sub{
            position: relative;
            
            .headerImg{
                img{
                    border: 15px;
                    width: 100%;
                    margin-top: -20px;

                }

            }
            .subHedWrap{
                max-width: 500px;
                width: fit-content;
                position: relative;
                left: 50%;
                right: 50%;
                margin-top: -50px;
                z-index: 3;
                border: 2px solid #fff;
                box-shadow: 2px 2px 10px grey;
                min-height: 100px;
                padding-right: 50px;
                padding-left: 50px;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 10px;
                backdrop-filter: blur(7px);
                
                h4{
                    text-transform: capitalize;
                }
            }
            .subText{
                margin: 10px;

            }
           
        }
         .wrap-cont{
            display: flex;
            flex-wrap: wrap;

             
                
            }
    }
}
















